const members = [
  {
    name: "Shri Anil Sachdev",
    desc: "Chairman",
  },
  {
    name: "Ms. Shibani Khorana",
    desc: "Manager",
  },
  {
    name: "Mr. Shailendra Kumar",
    desc: "Member",
  },
  {
    name: "Mr. Rakesh Jinsi",
    desc: "Member",
  },
  {
    name: "Smt. Sharda Chawla",
    desc: "Member",
  },
  {
    name: "Dr (Mrs) Vimla Veeraraghavan",
    desc: "Member",
  },
  {
    name: "Ms. Kumkum Bhatia",
    desc: "Member",
  },
  {
    name: "Mrs. Lata Vaidyanathan",
    desc: "Member",
  },
  {
    name: "Shri Jujhar Singh",
    desc: "Member",
  },
  {
    name: "Smt Kalpana Vaswani",
    desc: "Member",
  },
  {
    name: "Dr. Rajesh Hassija",
    desc: "Member",
  },
  {
    name: "Mr. Ashutosh Batta",
    desc: "Member",
  },
  {
    name: "Mr. Ravinder Kumar",
    desc: "Principal DE Nominee",
  },
  {
    name: "Ms. Pushpa Mahto",
    desc: "Principal DE Nominee",
  },
  {
    name: "Ms. Ruchi Tandon",
    desc: "Principal Advisory Board Nominee",
  },
  {
    name: "Ms. Alpana Kumar",
    desc: "Advisory Board Nominee",
  },
  {
    name: "Ms. Archana Soni",
    desc: "Principal / Member Secretary",
  },
  {
    name: "Mr. Arvind Bhardwaj",
    desc: "Parent Rep.",
  },
  {
    name: "Ms. Anila S Nath",
    desc: "Tr. Rep.",
  },
  {
    name: "Mrs. Gayatri Banerjee",
    desc: "Tr. Rep.",
  },
];

export default members;
