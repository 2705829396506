import React from "react";
import data from "./data";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";

export default function ManagingCommitteePage() {
  return (
    <Layout>
      <SEO
        title="Managing Committee | Chinmaya Vidyalaya"
        description="View the details of the managing committee including the Chairman, Managers, Members, Advisors, Parent and Student Representatives."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="managingcommittee">
        <h1 className="heading">Managing Committee</h1>
        <div className="managingcommittee__table">
          <div className="managingcommittee__table__row managingcommittee__table__row--heading">
            <div className="managingcommittee__table__col1">S. No.</div>
            <div className="managingcommittee__table__col2">Name</div>
            <div className="managingcommittee__table__col3">Designation</div>
          </div>
          {data.map((item, i) => (
            <div className="managingcommittee__table__row">
              <div className="managingcommittee__table__col1">{i + 1}</div>
              <div className="managingcommittee__table__col2">{item.name}</div>
              <div className="managingcommittee__table__col3">{item.desc}</div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
